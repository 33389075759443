require('normalize.css/normalize.css');
require('./styles/index.scss');

//http://127.0.0.1:8080/?projectID=760880b6-eee4-413f-a7fd-1759519e3301&mapToken=&type=comparison&pane1=ce57b48a-4906-472e-aef8-9ea5ee9fb159&pane2=b5a3126c-64f8-4e7c-b99c-40fc044dd2ad&layers=ca28c142-0cec-48bf-bd07-60c264b1d629,ce57b48a-4906-472e-aef8-9ea5ee9fb159&analyses=b5a3126c-64f8-4e7c-b99c-40fc044dd2ad

/*
** Expected URL structure
    http://localhost:8080
        ?projectID=<PROJECTID>
        &mapToken=<MAPTOKEN>
        &type=comparison || single
        &pane1=<LAYERID>
        &pane2=<LAYERID>
        &layers=<LAYERID>,<LAYERID>,<LAYERID>...
        &analyses=<ANALYSISID>,<ANALYSISID>,<ANALYSISID>...
*/

const DEFAULT_API_URL = process.env.BASE_API_URL;
const DEFAULT_TILE_URL = process.env.BASE_TILE_URL;

const BASEMAP = {
    "version": 8,
    "sources": {
        "carto-positron": {
            "type": "raster",
            "tiles": [
                "https://basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            ],
            "tileSize": 256
        }
    },
    "layers": [{
        "id": "carto-positron",
        "type": "raster",
        "source": "carto-positron",
        "minzoom": 0,
        "maxzoom": 22
    }]
};

var mapCenter = [0, 0];
var zoomLevel = 0;
var standardAttribute = `
    © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>
    © <a href="https://carto.com/attribution/">CartoDB</a>
`;

var leftAttribute = "";
var rightAttribute = "";

var projectID,
    mapToken,
    mapType,
    pane1,
    pane2,
    layers,
    analyses,
    selectLeft,
    selectRight,
    zoomExtentLeft,
    zoomExtentRight,
    BASE_TILE_URL,
    BASE_API_URL;

var projectLayersAndAnalyses = [];
var fetches = {};
window.fetches = {};


// Extract query parameters from the URL
function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}



window.addEventListener("load", () => {

    // Assign query params to variables
    projectID = getUrlVars()["projectID"];
    mapToken = getUrlVars()["mapToken"];
    mapType = getUrlVars()["type"];
    pane1 = getUrlVars()["pane1"];
    pane2 = getUrlVars()["pane2"];

    var tileUrlParam = getUrlVars()["tileUrl"];
    if (tileUrlParam === undefined) {
        BASE_TILE_URL = DEFAULT_TILE_URL;
    } else {
        BASE_TILE_URL = tileUrlParam;
    }

    var apiUrlParam =  getUrlVars()["apiUrl"];
    if (apiUrlParam === undefined) {
        BASE_API_URL = DEFAULT_API_URL;
    } else {
        BASE_API_URL = apiUrlParam;
    }

    console.log(DEFAULT_API_URL);
    console.log(BASE_API_URL);

    layers = (getUrlVars()["layers"] === "true") ? true
             : (getUrlVars()["layers"]  === "") ? false
             : getUrlVars()["layers"].split(",");
    analyses = (getUrlVars()["analyses"] === "true") ? true
               : (getUrlVars()["analyses"]  === "") ? false
               : getUrlVars()["analyses"].split(",");

    /*
    ** Get layer/analysis tile URL
    ** ${BASE_TILE_URL}/<projectID>/<type>/<layerID>/<z}/<x}/{y}/?mapToken=<mapToken>
    ** PARAM: <type> = "layers" || "analyses"
    ** PARAM: <layerID> = ID of layer or analysis
    */
    function getLayerTilesURL(pid, lid, mt) {

        var tileURL = `${BASE_TILE_URL}/${pid}/layers/${lid}/{z}/{x}/{y}/${mt ? "?mapToken=" + mt : ""}`
        return tileURL;
    };

    function getAnalysisTilesURL(pid, lid, mt) {

        var tileURL = `${BASE_TILE_URL}/${pid}/analyses/${lid}/{z}/{x}/{y}/${mt ? "?mapToken=" + mt : ""}`
        return tileURL;
    };

    // Assign DOM selects to variables
    selectLeft = document.getElementById("selectLeft");
    selectRight = document.getElementById("selectRight");
    zoomExtentLeft = document.getElementById("zoomExtentLeft");
    zoomExtentRight = document.getElementById("zoomExtentRight");

    if (mapType) {

        /*
        ** Mapbox GL
        ** initialize LEFT map
        */
        var leftMap = new mapboxgl.Map({
            container: "leftMap",
            style: BASEMAP,
            center: mapCenter,
            zoom: zoomLevel,
            attributionControl: false
        })
        .addControl(
            new mapboxgl.AttributionControl({
                compact: true,
                customAttribution: `${standardAttribute} | ${leftAttribute}`
            }),
            "top-left"
        )
        .addControl(
            new mapboxgl.FullscreenControl({
                container: document.querySelector(".app")
            }),
            "bottom-right"
        )
        .addControl(
            new mapboxgl.NavigationControl({
                showCompass: false
            }),
            "bottom-right"
        );
        leftMap.on('load', function() {
            leftMap.scrollZoom.disable();
            leftMap.dragRotate.disable();
            leftMap.boxZoom.enable();
            leftMap.doubleClickZoom.enable();
            leftMap.touchZoomRotate.enable();
            leftMap.keyboard.enable();
        });
    }


    if (mapType === "comparison") {

        /* Show right select */
        selectRight.setAttribute("style", "display: block");
        zoomExtentRight.setAttribute("style", "display: block");

        /*
        ** Mapbox GL
        ** initialize RIGHT map
        */
        var rightMap = new mapboxgl.Map({
            container: "rightMap",
            style: BASEMAP,
            center: mapCenter,
            zoom: zoomLevel,
            attributionControl: false
        })
        .addControl(
            new mapboxgl.AttributionControl({
                compact: true,
                customAttribution: `${standardAttribute} | ${rightAttribute}`
            }),
            "top-right"
        );
        rightMap.on('load', function() {
            rightMap.scrollZoom.disable();
            rightMap.dragRotate.disable();
            rightMap.boxZoom.enable();
            rightMap.doubleClickZoom.enable();
            rightMap.touchZoomRotate.enable();
        });

        // initialize mapbox gl compare plugin
        var map = new mapboxgl.Compare(leftMap, rightMap, {});
    }

    /*
    ** Create select options for layer selection
    ** PARAM: <pid> = projectID
    ** PARAM: <mt> = maptoken
    */
    function getProjectListObject(pid, mt) {

        var layerType = ["analyses", "layers"];

        /*
         ** layers/analyses object API GET request
         ** ${BASE_API_URL}/api/projects/<projectID>/<type>/?mapToken=<mapToken>
         */
        layerType.forEach(type => {
            switch(type) {
                case "analyses":
                    fetches["initanalyses"] = fetch(`${BASE_API_URL}/api/tool-runs?pageSize=99999&projectId=${pid}${mt ? "&mapToken=" + mt : ""}`);
                    break;
                case "layers":
                    fetches["initlayers"] = fetch(`${BASE_API_URL}/api/projects/${pid}/layers/?pageSize=99999${mt ? "&mapToken=" + mt : ""}`);
                    break;
            }
            fetches[`init${type}`]
                .then(resp => {
                    fetches[type] = resp.json();
                    window.fetches[type] = fetches[type];
                    return fetches[type];
                })
                .then((data) => {
                    function compare(l1, l2){

                        var l1Split = l1.name.split("|")
                        var l2Split = l2.name.split("|")

                        var l1key = l1Split[1] + l1Split[0]
                        var l2key = l2Split[1] + l2Split[0]
                        // console.log(l1key)
                        // console.log(l2key)
                        if (l1key > l2key) return 1;
                        if (l2key > l1key) return -1;
                        return 0;
                    }

                    data.results.sort(compare).forEach(resultObject => {

                        /* Create a new object containing response */
                        var result = new Object();
                            result.id = resultObject.id;
                            result.name = resultObject.name;
                            result.type = type;
                            if (resultObject.geometry) {
                                result.coordinates = resultObject.geometry.coordinates[0];
                            } else if (resultObject.layerGeometry) {
                                result.coordinates = resultObject.layerGeometry.coordinates[0];
                            } else {
                                result.coordinates = null;
                            }
                        /* If layers || analyses is set to true, push all results to an array */
                        if (layers === true && type === "layers") {
                            projectLayersAndAnalyses.push(result);
                        }
                        else if (analyses === true && type === "analyses") {
                            projectLayersAndAnalyses.push(result);
                        }
                        /* If layers || analyses is set to true, push all results to an array */
                        else if (layers && type === "layers") {
                            layers.forEach(layer => {
                                if (Object.is(layer, result.id)){
                                    projectLayersAndAnalyses.push(result);
                                    return;
                                }
                            });
                        }
                        else if (analyses && type === "analyses") {
                            analyses.forEach(analysis => {
                                if (Object.is(analysis, result.id)){
                                    projectLayersAndAnalyses.push(result);
                                    return;
                                }
                            });
                        }
                        else { return };
                    });
                })
                .then((data) => {
                    /* Loop through and create the DOM select options */
                    var optGroup = document.createElement("OPTGROUP")
                        optGroup.setAttribute("label", type);

                    projectLayersAndAnalyses.forEach(function(item, index, obj) {
                        if (item.type === type) {

                            var selectOption = document.createElement("OPTION")
                                selectOption.setAttribute("value", item.id);
                                selectOption.setAttribute("data-coordinates", JSON.stringify(item.coordinates[0]));
                                selectOption.setAttribute("data-type", item.type);

                                var selectOptionText = document.createTextNode(item.name);
                                selectOption.appendChild(selectOptionText);

                                optGroup.appendChild(selectOption);
                        }
                    });

                    /* Clone options and append to both selects in DOM */
                    var optGroupClone = optGroup.cloneNode(true);
                    selectLeft.appendChild(optGroup);
                    selectRight.appendChild(optGroupClone);
                })
                .then(() => {
                    selectLeft.value = pane1;
                    selectRight.value = pane2;
                })
            })
    };

    /*
    ** Load initial map layers
    ** PARAM: <paneTarget> = pane1 || pane2
    ** PARAM: <mapTarget> = leftMap || rightMap
    */
    function onMapReady(paneTarget, mapTarget) {
        var mapLayer = projectLayersAndAnalyses.find(i => paneTarget === i.id),
            mlID = mapLayer.id,
            mlType = mapLayer.type,
            mlCoordinates = mapLayer.coordinates[0];

        var bounds = mlCoordinates.reduce(function (bounds, coord) {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(mlCoordinates[0], mlCoordinates[0]));

        if (mlType === 'analyses') {
            var tileURL = getAnalysisTilesURL(projectID, mlID, mapToken)
        } else {
            var tileURL = getLayerTilesURL(projectID, mlID, mapToken)
        }

        mapTarget.on('load', function() {
            mapTarget.fitBounds(bounds, {
                padding: 20
            });
            mapTarget.addSource(`${mapTarget}Source`, {
                type: 'raster',
                tiles: [tileURL],
                tileSize: 256
            });
            mapTarget.addLayer({
                'id': `${mapTarget}Layer`,
                'type': 'raster',
                'source': `${mapTarget}Source`,
                'layout': {
                    'visibility': 'visible'
                }
            });
        });
    }

    /*
    ** Load map layers on select change
    ** PARAM: <paneTarget> = pane1 || pane2
    ** PARAM: <mapTarget> = leftMap || rightMap
    */
    function onMapSelect(that, paneTarget) {
        var selected = that.options[that.selectedIndex];
        var selectedID = that.value;
        var selectedType = selected.getAttribute("data-type");

        if (selectedType === 'analyses') {
            var tileURL = getAnalysisTilesURL(projectID, selectedID, mapToken)
        } else {
            var tileURL = getLayerTilesURL(projectID, selectedID, mapToken)
        }

        if (paneTarget.getStyle().layers.length > 1) {
            var currentLayer = paneTarget.getStyle().layers[1];

            if (paneTarget.getLayer(currentLayer.id)){
                paneTarget.removeLayer(currentLayer.id);
            }

            if (paneTarget.getSource(currentLayer.source)){
                paneTarget.removeSource(currentLayer.source);
            }
        }

        paneTarget.addSource('leftMapSource', {
            type: 'raster',
            tiles: [tileURL],
            tileSize: 256
        });
        paneTarget.addLayer({
            'id': 'leftMapLayer',
            'type': 'raster',
            'source': 'leftMapSource',
            'layout': {
                'visibility': 'visible'
            }
        });
    }


    getProjectListObject(projectID, mapToken);

    Promise.all([fetches["initanalyses"], fetches["initlayers"]]).then(() => {
        Promise.all([fetches["analyses"], fetches["layers"]]).then(data => {

            /* Load leftmap layer */
            onMapReady(pane1, leftMap);

            if ( pane2 && mapType === "comparison" ) {
                /* Load rightmap layer */
                onMapReady(pane2, rightMap);
            }
        }).catch(error => {
            console.log(error.message)
        })
    }).catch(error => {
        console.log(error.message)
    });

    /* Change map layers based on <select> changes */
    selectLeft.addEventListener("change", function() {
        onMapSelect(this, leftMap);
    });
    selectRight.addEventListener("change", function() {
        onMapSelect(this, rightMap);
    });

    function zoomToExtent(selectBox, mapTarget) {
        var selected = selectBox.options[selectBox.selectedIndex];
        var selectedCoordinates = JSON.parse(selected.getAttribute('data-coordinates'));
        var bounds = selectedCoordinates.reduce(function (bounds, coord) {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(selectedCoordinates[0], selectedCoordinates[0]));

        mapTarget.fitBounds(bounds, {
            padding: 20
        });
    }

    /* Zoom to extent of map layers */
    zoomExtentLeft.addEventListener("click", function() {
        zoomToExtent(selectLeft, leftMap)
    });
    zoomExtentRight.addEventListener("click", function() {
        zoomToExtent(selectRight, rightMap)
    });
});
